body {
  --ads-color-orange-900 : #032121;
  --ads-color-orange-800 : #064744; /* Secondary */
  --ads-color-orange-700 : #0f6e66;
  --ads-color-orange-600 : var(--ads-color-brand-hover);
  --ads-color-orange-500 : var(--ads-color-brand); /* Primary */
  --ads-color-orange-400 : #50c7b1;
  --ads-color-orange-300 : #77d4bf;
  --ads-color-orange-200 : #a2e0d1;
  --ads-color-orange-100 : #d1ede5;
  --ads-color-orange-50 : #ebfaf5;
  --ads-old-color-rock-spray: var(--ads-color-brand-hover);
  --ads-old-color-bridesmaid: #a2e0d1;
  --ads-old-color-pot-pourri: #d1ede5;

  --ads-primary-color: var(--ads-color-orange-500);
  --ads-primary-text-color: var(--ads-color-black-750);

  /** Naming convention for tokens
  *
  * --ads-<component>-<componentType>-<state>-<property>-<subProperty>
  *
  * component: button, checkbox, dropdown, input, link, list, menu, radio, select, textarea...
  * subComponent: label, divider...
  * componentType: primary, secondary...
  * state: hover, focus, active, disabled, default...
  * property: color, background, border...
  * subProperty: color, width, radius...
  *
  */

  /** API Pane */
  --ads-api-pane-settings-text-color: var(--ads-old-color-gray-10);

  /** AppIcon  */
  --ads-app-icon-normal-color: var(--ads-color-black-550);
  --ads-app-icon-background-color: var(--ads-color-black-25);

  /* Breadcrumbs */
  --ads-breadcrumbs-list-text-color: var(--ads-old-color-dove-gray);
  --ads-breadcrumbs-separator-text-color: var(--ads-old-color-dove-gray);
  --ads-breadcrumbs-active-text-color: var(--ads-color-black-900);

  /** Button Tab */
  --ads-button-tab-selected-border-color: var(--ads-old-color-gray-10);
  --ads-button-tab-border-color: var(--ads-color-black-250);
  --ads-button-tab-focus-background-color: var(--ads-old-color-gallery-2);
  --ads-button-tab-hover-background-color: var(--ads-old-color-gallery-2);
  --ads-button-tab-svg-path-fill-color: var(--ads-old-color-gray-7);

  /** Callout */
  --ads-callout-info-text-color: #D44100;
  --ads-callout-info-background-color: #F8F3F0;
  --ads-callout-success-text-color: var(--ads-old-color-jade);
  --ads-callout-success-background-color: #E4F4ED;
  --ads-callout-danger-text-color: var(--ads-old-color-pomegranate);
  --ads-callout-danger-background-color: #F9E9E9;
  --ads-callout-warning-text-color: var(--ads-old-color-sun);
  --ads-callout-warning-background-color: #FAF3E3;

  /** Checkbox */
  --ads-checkbox-disabled-checked-background-color: #E8E8E8;
  --ads-checkbox-disabled-checked-border-color: #E8E8E8;
  --ads-checkbox-default-unchecked-border-color: #404040;
  --ads-checkbox-after-disabled-checked-border-color: #939090;
  --ads-checkbox-after-default-checked-border-color: var(--ads-color-black-0);
  --ads-checkbox-label-text-color: var(--ads-old-color-alto);

  /** ColorSelector */
  --ads-color-selector-shadow-color: #E8E8E8;
  --ads-color-selector-checkmark-color: #000000;

  /** Dialog Component */
  --ads-dialog-component-default-background-color: var(--ads-color-black-0);
  --ads-dialog-component-header-text-text-color: var(--ads-color-black-900);
  --ads-dialog-component-dialog-header-underline-color: #C5C5C5;
  --ads-dialog-component-icon-background-color: #FFDEDE;

  /** Display Image Upload */
  --ads-display-image-upload-background-color: var(--ads-old-color-pigeon-post);
  --ads-display-image-upload-label-text-color: var(--ads-old-color-royal-blue);

  /** Dropdown */
  --ads-dropdown-hover-background-color : var(--ads-old-color-gallery-2);
  --ads-dropdown-selected-text-color: var(--ads-old-color-gray-10);
  --ads-dropdown-disabled-header-text-color: #9F9F9F;
  --ads-dropdown-disabled-header-background-color: var(--ads-color-black-0);
  --ads-dropdown-default-header-text-color: var(--ads-color-black-750);
  --ads-dropdown-selected-header-text-color: var(--ads-old-color-gray-10);
  --ads-dropdown-default-menu-border-color: var(--ads-color-black-100);
  --ads-dropdown-default-menu-subtext-text-color: var(--ads-old-color-gray-7);
  --ads-dropdown-selected-menu-subtext-text-color: var(--ads-color-black-550);
  --ads-dropdown-default-menu-text-color: var(--ads-color-black-750);
  --ads-dropdown-default-menu-hover-text-color: var(--ads-old-color-gray-10);
  --ads-dropdown-default-menu-hover-background-color: var(--ads-color-black-200);
  --ads-dropdown-default-icon-fill-color: var(--ads-color-black-550);
  --ads-dropdown-default-icon-background-color: var(--ads-color-black-250);
  --ads-dropdown-default-icon-selected-fill-color: var(--ads-old-color-gray-7);
  --ads-dropdown-default-icon-hover: var(--ads-color-black-0);
  --ads-dropdown-default-dropdown-trigger-border-color: var(--ads-color-black-900);
  --ads-dropdown-default-close-hover-background-color : var(--ads-old-color-gallery-2);

  /** Emoji Reactions */
  --ads-emoji-reactions-bubble-background-color: var(--ads-color-black-75);
  --ads-emoji-reactions-bubble-active-background-color: var(--ads-old-color-bridesmaid);
  --ads-emoji-reactions-bubble-active-border-color: var(--ads-old-color-rock-spray);
  --ads-emoji-reactions-bubble-hover-background-color: var(--ads-old-color-gallery-2);
  --ads-emoji-reactions-count-text-color: var(--ads-old-color-dove-gray);
  --ads-emoji-reactions-count-active-text-color: var(--ads-old-color-rock-spray);
  --ads-emoji-reactions-count-hover-text-color: var(--ads-color-black-750);

  /** Gif Player */
  --ads-gif-player-icon-path-fill-color: var(--ads-old-color-silver);
  --ads-gif-player-icon-circle-fill-color: var(--ads-old-color-gray-10);
  --ads-gif-player-text-color: var(--ads-old-color-dove-gray-3);
  --ads-gif-player-overlay-background-color: var(--ads-color-black-0);

  /** HighlightText */
  --ads-highlight-text-default-text-color: var(--ads-color-orange-800);
  --ads-highlight-text-default-background-color: var(--ads-color-orange-100);

  /** Icon Selector */
  --ads-icon-selector-scrollbar-thumb-background-color: var(--ads-color-black-450);
  --ads-icon-selector-app-icon-background-color: var(--ads-color-black-25);
  --ads-icon-selector-selected-icon-fill-color: var(--ads-color-black-0);

  /** Info */
  --ads-info-main-text-color: var(--ads-color-brand);

  /** Input */
  --ads-input-focus-border-color: var(--ads-color-black-900);

  /** Intent Colors */
  --ads-intent-color-primary: var(--ads-old-color-jade);
  --ads-intent-color-secondary: var(--ads-old-color-black-pearl);
  --ads-intent-color-success: var(--ads-old-color-cornflower-blue);
  --ads-intent-color-danger: var(--ads-old-color-danger-red);
  --ads-intent-color-none: var(--ads-color-black-200);
  --ads-intent-color-warning: var(--ads-old-color-jaffa);
  --ads-intent-color-success-light: var(--ads-old-color-jade);

  /** Label with Tooltip */
  --ads-label-with-tooltip-default-text-color: var(--ads-color-black-550);
  --ads-label-with-tooltip-default-icon-fill-color: var(--ads-color-black-450);
  --ads-label-with-tooltip-hover-icon-fill-color: var(--ads-color-black-550);

  /** Menu */
  --ads-menu-default-border-color: #404040;

  /** Menu Divider */
  --ads-menu-divider-border-color: var(--ads-old-color-mercury);

  /** Multiswitch */
  --ads-multiswitch-tab-list-background-color: var(--ads-old-color-mercury);
  --ads-multiswitch-border-color: var(--ads-color-black-250);
  --ads-multiswitch-selected-background-color: var(--ads-color-black-0);
  --ads-multiswitch-tab-header-background-color: var(--ads-color-black-0);

  /** Notification Banner */
  --ads-notification-banner-error-text-color: #C91818;
  --ads-notification-banner-error-background-color:  var(--ads-old-color-fair-pink);
  --ads-notification-banner-light-enterprise-text-color: #E8F5FA;
  --ads-notification-banner-dark-enterprise-text-color: #00407D;

  /** Numbered Step */
  --ads-numbered-step-text-color: var(--ads-color-black-250);
  --ads-numbered-step-line-background-color: var(--ads-old-color-black);

  /** Property Pane */
  --ads-property-pane-default-zoom-button-background-color: #2B2B2B;
  --ads-property-pane-default-radio-group-button-text-color: #D4D4D4;
  --ads-property-pane-default-button-text-color: #090707;

  /** Radio */
  --ads-radio-default-text-color: var(--ads-old-color-gray-10);
  --ads-radio-default-border-color: var(--ads-old-color-mercury);
  --ads-radio-disabled-background-color: #C5C5C5;

  /** Rectangular Switch */
  --ads-rectangular-switch-background-color: var(--ads-color-black-0);
  --ads-rectangular-switch-hover-background-color: var(--ads-color-black-0);
  --ads-rectangular-switch-slider-border-color: var(--ads-color-black-450);
  --ads-rectangular-switch-slider-background-color: var(--ads-primary-color);
  --ads-rectangular-switch-light-text-color: var(--ads-color-black-0);
  --ads-rectangular-switch-dark-text-color: var(--ads-color-black-470);

  /** Scroll Indicator */
  --ads-scroll-indicator-light-thumb-background-color: var(--ads-old-color-black-750-opacity-50);
  --ads-scroll-indicator-light-track-background-color: var(--ads-old-color-white-opacity-50);
  --ads-scroll-indicator-dark-thumb-background-color: var(--ads-old-color-alto-opacity-50);
  --ads-scroll-indicator-dark-track-background-color: var(--ads-old-color-gray-10-opacity-50);

  /** Search Input */
  --ads-search-input-border-color: var(--ads-color-black-400);
  --ads-search-input-active-border-color: var(--ads-color-black-900);

  /** Showcase Carousel */
  --ads-showcase-carousel-dot-background-color: var(--ads-old-color-bridesmaid);
  --ads-showcase-carousel-dot-active-background-color: var(--ads-primary-color);

  /** Statusbar */
  --ads-statusbar-active-background-color: var(--ads-primary-color);
  --ads-statusbar-height: 92px;
  --ads-statusbar-active-p-text-color: var(--ads-color-black-0);
  --ads-statusbar-active-svg-fill-color: var(--ads-color-black-0);
  --ads-statusbar-status-text-color: var(--ads-old-color-black);
  --ads-statusbar-progress-bar-active-background-color: var(--ads-old-color-crusta);
  --ads-statusbar-progress-bar-background-color: var(--ads-color-black-0);

  /** StepComponent */
  --ads-step-component-wrapper-background-color: var(--ads-color-black-250);
  --ads-step-component-wrapper-svg-fill-color: var(--ads-color-black-750);
  --ads-step-component-input-wrapper-text-color: var(--ads-color-black-750);
  --ads-step-component-input-wrapper-background-color: var(--ads-color-black-0);

  /** Table */
  --ads-table-table-row-background-color : var(--ads-color-black-25);
  --ads-table-table-header-default-text-color : var(--ads-color-black-470);
  --ads-table-table-header-hover-text-color: var(--ads-color-black-850);
  --ads-table-table-header-hover-icon-path-color: var(--ads-color-black-850);
  --ads-table-table-row-first-table-data-default-text-color: var(--ads-color-black-850);
  --ads-table-table-row-table-data-default-text-color: var(--ads-color-black-550);
  --ads-table-table-row-table-data-border-bottom-color: var(--ads-old-color-mercury);
  --ads-table-table-row-hover-background-color : var(--ads-color-black-75);
  --ads-table-table-row-hover-icon-path-color: var(--ads-old-color-gray-10);
  --ads-table-table-row-first-table-data-hover-text-color: var(--ads-old-color-gray-10);
  --ads-table-table-row-table-data-hover-text-color: var(--ads-color-black-850);

  /** TableDropdown  */
  --ads-table-dropdown-background-color: var(--ads-color-black-0);
  --ads-table-dropdown-selected-background-color: var(--ads-color-black-75);
  --ads-table-dropdown-selected-hover-text-color: var(--ads-color-black-850);

  /** Tabs */
  --ads-tabs-default-tab-list-text-color: var(--ads-old-color-gray-7);
  --ads-tabs-default-tab-list-svg-fill-color: var(--ads-color-black-0);
  --ads-tabs-default-tab-list-response-viewer-background-color: var(--ads-old-color-mercury);
  --ads-tabs-default-tab-focus-response-viewer-border-color: var(--ads-color-black-250);
  --ads-tabs-tab-selected-svg-fill-color: var(--ads-color-black-750);
  --ads-tabs-tab-selected-response-viewer-background-color: var(--ads-color-black-0);
  --ads-tabs-tab-selected-response-viewer-border-color: var(--ads-color-black-250);
  --ads-tabs-tab-title-response-viewer-text-color: var(--ads-primary-text-color);
  --ads-tabs-count-background-color: var(--ads-old-color-mercury);
  --ads-tabs-title-wrapper-text-color: var(--ads-old-color-gray-7);
  --ads-tabs-title-wrapper-hover-text-color: var(--ads-color-black-750);
  --ads-tabs-title-wrapper-icon-fill-color: var(--ads-old-color-gray-7);

  /** Tab Item Background fill */
  --ads-tab-item-text-color: var(--ads-primary-text-color);
  --ads-tab-item-focus-background-color: var(--ads-color-black-75);
  --ads-tab-item-focus-text-color: var(--ads-color-black-900);

  /** Tag Input */
  --ads-tag-input-background-color: var(--ads-color-black-0);
  --ads-tag-input-border-color: var(--ads-color-black-250);
  --ads-tag-input-text-color: var(--ads-color-black-850);
  --ads-tag-input-placeholder-color: var(--ads-old-color-alto);
  --ads-tag-input-active-border-color: var(--ads-primary-color);
  --ads-tag-input-active-box-shadow: "none";
  --ads-tag-input-tag-text-color: var(--ads-color-black-0);
  --ads-tag-input-tag-background-color: var(--ads-primary-color);

  /** Text */
  --ads-text-color: var(--ads-primary-text-color);
  --ads-text-heading-color: var(--ads-color-black-850);
  --ads-text-highlight-color: var(--ads-color-black-0);

  /** TextInput */
  --ads-text-input-text-box-default-background-color: #ffffff;
  --ads-text-input-text-box-default-text-color: #090707;
  --ads-text-input-text-box-default-border-color: #E0DEDE;
  --ads-text-input-text-box-disabled-background-color: #F0F0F0;
  --ads-text-input-text-box-disabled-text-color: #6D6D6D;
  --ads-text-input-text-box-disabled-border-color: #F0F0F0;
  --ads-text-input-text-box-read-only-background-color: #F0F0F0;
  --ads-text-input-text-box-read-only-text-color: #716E6E;
  --ads-text-input-text-box-read-only-border-color: #F0F0F0;
  --ads-text-input-text-box-hover-background-color: #FAFAFA;
  --ads-text-input-icon-path-color: #716E6E;
  --ads-text-input-placeholder-text-color: #A9A7A7;
  --ads-text-input-helper-text-text-color: #858282;

  /* Toggle */
  --ads-toggle-slider-background-color: var(--ads-color-black-250);
  --ads-toggle-slider-loading-background-color: var(--ads-old-color-mercury);
  --ads-toggle-slider-disabled-on-background-color: var(--ads-color-black-0);
  --ads-toggle-slider-disabled-off-background-color: var(--ads-color-black-0);
  --ads-toggle-on-background-color: var(--ads-color-orange-500);
  --ads-toggle-hover-on-background-color: var(--ads-old-color-rock-spray);
  --ads-toggle-hover-off-background-color: var(--ads-color-black-450);
  --ads-toggle-disabled-on-background-color: var(--ads-old-color-bridesmaid);
  --ads-toggle-disabled-off-background-color: var(--ads-color-black-250);
  --ads-toggle-spinner-stroke-color: var(--ads-color-black-450);

  /** Tree Dropdown */
  --ads-tree-dropdown-menu-default-background-color: var(--ads-color-black-5);
  --ads-tree-dropdown-menu-default-text-color: var(--ads-color-black-750);
  --ads-tree-dropdown-menu-selected-text-color: var(--ads-color-black-750);
  --ads-tree-dropdown-target-background-color: var(--ads-color-black-0);

  /** Uncategorized/common */
  --ads-danger-main: #E22C2C;
  --appsmith-input-focus-border-color: var(--ads-color-black-900);
  --ads-text-color-on-dark-background: var(--ads-color-black-0);
  --ads-property-pane-default-label-fill-color : var(--ads-color-black-750);
  --ads-small-header-height: 32px;
  --ads-bottom-bar-height: 34px;

  /** Uneditable Text Field */
  --ads-uneditable-text-field-label-text-color: var(--ads-color-black-750);
}